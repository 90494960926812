<template>
  <div class="slide" :style="{ backgroundImage: `url(${data.background})` }">
    <div class="slide__wrapper">
      <div class="slide__information">
        <div class="trainer-item__tag-section">
          <DiveTag :text="data.category" />
        </div>
        <h1 class="slide-title">
          {{ data.title }}
        </h1>
        <div class="information-button">
          <router-link
            :to="{
              name: 'SimulatorCaseView',
              params: {
                locale: $route.params.locale,
                id: data?.application_id,
              },
            }"
            class="btn btn-full"
            >{{ $t('more') }}</router-link
          >
          <router-link
            :to="{
              name: 'SimulatorsView',
              params: { locale: $route.params.locale },
            }"
            class="btn-link"
            >{{ $t('all-trainers') }}</router-link
          >
        </div>
      </div>
      <div class="slide__video">
        <a
          v-if="data.video !== 'NULL'"
          href="#"
          class="thumb"
          @click="$emit('set_video', data.video)"
        >
          <div
            class="thumb-image sim-thumb"
            :style="{ backgroundImage: `url(${data.videoCover})` }"
          ></div>
        </a>
      </div>
      <div class="slide__bottom">
        <div class="slider-actions">
          <button class="prev-btn" @click="$emit('to_prev_slide')">
            <img src="@/assets/images/slider/arrow-left.svg" alt="icon" />
          </button>
          <span class="slider-index">
            <span class="current-slide">{{
              zero_before_number(index + 1)
            }}</span>
            <span class="slash"> / </span>
            <span class="total-slides">{{ zero_before_number(total) }}</span>
          </span>
          <button class="next-btn" @click="$emit('to_next_slide')">
            <img
              src="@/assets/images/slider/arrow-left.svg"
              alt="icon"
              class="rotate-180"
            />
          </button>
        </div>
        <div class="slider-social">
          <SocialNetworks type="monocolor" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DiveTag from '@/shared/ui/DiveTag.vue';
import SocialNetworks from '@/shared/ui/SocialNetworks.vue';
import { zero_before_number } from '@/shared/lib/number_format';

export default {
  name: 'MainSlide',
  components: { SocialNetworks, DiveTag },
  props: {
    data: {
      type: Object,
    },
    total: Number,
    index: Number,
  },
  emits: ['set_video', 'to_next_slide', 'to_prev_slide'],
  methods: { zero_before_number },
};
</script>

<style scoped lang="scss">
.slide {
  height: 100vh;
  background-blend-mode: soft-light;
  background-size: cover;
  background: rgba(0, 0, 0, 0.6) no-repeat center;
  &__wrapper {
    position: relative;
    height: inherit;
    z-index: 10;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    row-gap: 100px;
    align-items: center;
    justify-content: space-between;
    align-content: center;
    width: initial;
  }
  &__information {
    width: 65%;
    @media screen and (max-width: 720px) {
      width: 90%;
      margin: auto;
    }
    .trainer-item__tag-section {
      display: flex;
      justify-content: flex-start;
      padding-bottom: 24px;
      .trainer-item__tag {
        font-family: 'Raleway', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 150%;
        color: #ffffff;
        text-align: center;
        padding: 2px 12px;
        mix-blend-mode: normal;
        border: 1px solid #a4a6b5;
        border-radius: 100px;
      }
    }
    .slide-title {
      font-family: 'Proxima Nova', sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 48px;
      line-height: 120%;
      letter-spacing: 1px;
      color: #ffffff;
      @media screen and (max-width: 425px) {
        font-size: 36px;
      }
    }
    .information-button {
      padding-top: 52px;
      display: flex;
      align-items: center;
      gap: 40px;
      @media screen and (max-width: 425px) {
        padding-top: 20px;
        gap: 20px;
      }
    }
  }
  &__video {
    max-width: 270px;
    max-height: 360px;
    width: 30%;
    height: 100%;
    display: block;
    @media screen and (max-width: 720px) {
      display: none;
    }
    .thumb {
      .thumb-image {
        width: 100%;
        height: 100%;
        background: rgba(24, 26, 71, 0.3);
        border-radius: 4px;
        position: relative;
        transition: background 0.3s ease-in-out;
        &:after {
          content: url('@/assets/images/icons/play-white.svg');
          position: absolute;
          top: calc(100% / 2 - 63px / 2);
          left: calc(100% / 2 - 63px / 2);
          opacity: 0.8;
        }
      }
      .sim-thumb {
        background-blend-mode: soft-light;
        background-repeat: no-repeat;
        background-position: center;
      }
      &:hover {
        .thumb-image {
          background-color: rgba(24, 26, 71, 0.75);
          transition: background 0.3s ease-out;
          &:after {
            opacity: 1;
          }
        }
      }
    }
  }
  &__bottom {
    display: flex;
    justify-content: space-between;
    flex-grow: 1;
    align-items: center;
    @media screen and (max-width: 426px) {
      flex-direction: column-reverse;
      row-gap: 40px;
      position: absolute;
      top: 85%;
      left: 50%;

      transform: translate(-50%, -50%);
    }
    .slider-actions {
      display: flex;
      column-gap: 50px;
      align-items: center;
      .slider-index {
        font-family: 'Raleway', sans-serif;
        font-size: 18px;
        font-weight: 900;
        line-height: 24px;
        letter-spacing: 1px;
        vertical-align: center;
        display: flex;
        column-gap: 5px;
        .current-slide {
          color: #ffffff;
        }
        .slash {
          color: #a4a6b5;
        }
        .total-slides {
          font-weight: 500;
          font-size: 16px;
          color: #a4a6b5;
        }
      }
      button {
        border: 1px solid #ffffff66;
        background-color: #ffffff1a;
        border-radius: 4px;
        width: 50px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: background-color 0.3s ease;

        &:hover {
          background-color: #3a3a3a;
        }

        &:focus {
          outline: none;
          box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.2);
        }
        .rotate-180 {
          transform: rotate(180deg);
        }
      }
    }
  }
}
</style>
